<template>
  <footer>
    <div>
    &copy; 2023 Azisaba Network |
    <a href="https://www.azisaba.net" target="_blank">Official HP</a> |
    <a href="https://discord.com/invite/azisaba" target="_blank">Official Discord</a> |
    <a href="https://azisaba.buycraft.net/" target="_blank">Donation Site</a> |
    </div>
  </footer>
</template>

<script>
export default {
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
*{
  margin:0;
  padding: 0;
  vertical-align: middle;
}

div {
  margin-top: 2.8vh;
  margin-bottom: 2.6vh;
}
footer {
  margin-top: auto;
  background-color: #2c3e50;
  color: aliceblue;
  min-height: 7vh;

  width:100%;

  bottom: 0;
}

footer a{
 color: aliceblue;
}

</style>